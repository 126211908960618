.store-container {
  font-family: var(--ion-font-family-noto-sans);
  font-weight: var(--ion-font-weight);
  font-style: var(--ion-font-style);
  .pelzer-store-image-section {
    margin-bottom: 15px;
    ion-img {
      width: 100vw;
      height: 25vh;
      margin-left: -5px;
      margin-top: -5px;
      margin-bottom: 10px;
    }
  }

  .pelzer-heading-wrapper {
    padding-left: 16px;
    padding-right: 16px;
    padding-top: 37px;
    .pelzer-store-title {
      letter-spacing: var(--ion-letter-spacing);
    }

    .pelzer-store-subtitle {
      margin-top: var(--ion-margin-top-12px);
      left: 4%;
      right: 4.53%;
      bottom: 32.76%;
      font-size: 16px;
      line-height: 24px;
      color: #808285;
    }

    .pelzer-store-button {
      height: 60px;
      border: 1px solid #eeeeee;
      box-sizing: border-box;
      border-radius: 8px;
      background-color: #ffffff;
      margin-top: 40px;
      padding-top: 12px;
      display: flex;
      flex-direction: row;
      justify-content: space-between;

      .pelzer-store-button-text {
        margin-left: 2vw;
        font-weight: bold;
        width: 100%;
        background-color: #ffffff;
      }

      .forward-icon-for-store {
        width: 100%;
        height: var(--ion-height-24px);
        color: var(--ion-color-common-white-red-light);
        
      }
    }
  }
}


/*--------ipad-------- */
@media only screen and (min-width: 768px) and (max-height: 1024px) {
  .store-container {
    .pelzer-heading-wrapper {
      padding-left: 16px;
      padding-top: 45px;
      padding-right: 15px;
    }
    .pelzer-store-image-section {
    ion-img {
      height: 250px;
    }
  }
  }
}

//iphone 5/se
@media only screen and (width: 320px) and (height:568px){
  .pelzer-store-title {
    letter-spacing: var(--ion-letter-spacing);
    font-size: 28px;
    margin-top: -55px;
  }
  .store-container .pelzer-heading-wrapper .pelzer-store-subtitle {
    margin-top: 12px;
    left: 4%;
    right: 4.53%;
    bottom: 32.76%;
    font-size: 16px;
    line-height: 24px;
    color: #808285;
  }
  .store-container .pelzer-heading-wrapper .pelzer-store-button {
    height: 40px;
    border: 1px solid #eeeeee;
    box-sizing: border-box;
    border-radius: 8px;
    background-color: #ffffff;
    margin-top: 15px;
    padding-top: 5px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}
.store-container .pelzer-heading-wrapper .pelzer-store-button .pelzer-store-button-text{
  font-size:16px;
  width: 140%;
  margin-top:-3px ;
  margin-left: 9px;
}
}

//iphone6/7/8
@media only screen and (width: 375px) and (height:667px){
  .pelzer-store-title {
    letter-spacing: var(--ion-letter-spacing);
    font-size: 28px;
    margin-top: -20px;
  }
  .store-container .pelzer-heading-wrapper .pelzer-store-subtitle {
    margin-top: 30px;
    left: 4%;
    right: 4.53%;
    bottom: 32.76%;
    font-size: 16px;
    line-height: 24px;
    color: #808285;
  }
  .store-container .pelzer-heading-wrapper .pelzer-store-button {
    height: 45px;
    border: 1px solid #eeeeee;
    box-sizing: border-box;
    border-radius: 8px;
    background-color: #ffffff;
    margin-top: 50px;
    padding-top: 3px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}
.store-container .pelzer-heading-wrapper .pelzer-store-button .pelzer-store-button-text{
  font-size:16px;
}


}

