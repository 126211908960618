@import "../../theme/variables.scss";

.media-container {
  --padding-top: 5px;
  font-family: Noto Sans;

  .media-heading {
    position: absolute;
    height: 48px;
  }
  
  .img-wrapper {
    margin-top: 40px;
    margin-bottom: 80px;

    .media-list-spinner {
      height: 70vh;
      display: table;
      margin-left: auto;
      margin-right: auto;
    }

    .image-bild-button {
      position: relative;
      ion-img {
        position: relative;
        padding-bottom: 3px;
        display: block;
        width: 100%;
        left: 0px;
        top: 0px;
        z-index: -1;
        height: 170px;
      }

      .bottom-left {
        position: absolute;
        bottom: 8px;
        right: 8px;
        color: #ffffff;
        font-size: 12px;
        line-height: 18px;
        background-color: black;
        border-radius: 32px;
        line-height: 18px;
        background: rgba(41, 41, 41, 0.88);
        padding: 1px 8px 1px 8px;
        font-family: Noto Sans;
        font-style: normal;
        font-weight: normal;
      }
     }
  }

  img {
    display: block;
    width: 100%;
    height: 151px;
  }
}

// For iPad
@media only screen and (min-width: 768px) and (max-width: 3000px) and (min-height: 604px) and (max-height: 2000px) {
  .media-container {
    .img-wrapper {
      .media-list-spinner {
        display: table;
        margin-left: auto;
        margin-right: auto;
        height: 32vh;
        transform: scale(1.4);
      }
    }
  }
}

// For iPad pro
@media only screen and (width: 1024px) and (height: 1366px) and (orientation: portrait) {
  .media-container {
    .img-wrapper {
      .media-list-spinner {
        display: table;
        margin-left: auto;
        margin-right: auto;
        height: 25vh;
        transform: scale(1.7);
      }
    }
  }
}