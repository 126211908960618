

.youtube-container {
    --padding-top: 100px;
  font-family: var(--ion-font-family-noto-sans);
  font-weight: var(--ion-font-weight);
  font-style: var(--ion-font-style);
  line-height: var(--ion-line-height-type2);

  .youtube-heading {
    text-align: center;
  }

  .youtube-link-input-row{
    margin-top: 40px;
    border-radius: 8px;
    margin-left: 12px;
    margin-right: 11px;
    
    .youtube-link-input{ 
        font-size: 16px;
        height: 60px;
    }
  }

  .searchbutton {
    margin-top: 20px;
  }

}
