@import "../../src/theme/variables.scss";

.home-container {
  --padding-top: 30px;
  font-family: var(--ion-font-family-noto-sans);
  font-weight: var(--ion-font-weight);
  font-style: var(--ion-font-style);
  line-height: var(--ion-line-height-type2);

  .home-heading {
    padding-left: 20px;
    .heading-title {
      letter-spacing: var(--ion-letter-spacing);
    }

    .heading-subtitle {
      margin-top: var(--ion-margin-top-12px);
      margin-bottom: 25px;
    }
  }

  .nodisplay {
    margin-top: 40px;
    padding: 10px;
    width: 100%;
    text-align: center;
  }

  .my-custom-slider {
    padding-top: 0px;
    padding-bottom: 25px;
  }

  ion-grid {
    height: 100%;
  }

  .sliderSpinner {
    align-self: center;
    height: 35vh;
  }
}

.loading-Spinner {
  position: fixed;
  z-index: 1031;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

ion-slides {
  .swiper-pagination-bullet {
    background: var(--ion-color-common-very-dark-blue);
    opacity: var(--ion-opacity-05);
    width: var(--ion-width-10px);
    height: var(--ion-height-10px);
  }

  .swiper-pagination-bullet-active {
    opacity: var(--ion-opacity-1);
    background: dark;
  }
}

:host(.button-solid) {
  --background: var(--ion-color-common-white-blue-light);
  --color: var(--ion-color-common-white-red-light);
  --border: var(--ion-border-5px);
}

ion-item {
  --padding-start: 0px;
}

ion-content {
  --offset-bottom: auto !important;
  --overflow: hidden;
  overflow: auto;
  &::-webkit-scrollbar {
    display: none;
  }
};
// For iPad and responsive after ipad
@media only screen and (min-width: 768px) and (max-width: 3000px) and (min-height: 1024px) and (max-height: 2000px) {
  .cardComponent-wrapper {
    height: var(--ion-height-520px) !important;
    margin-top: var(--ion-margin-top-65px);
    ion-img {
      height: var(--ion-height-260px);
    }
  }

  .loading-Spinner {
    display: table;
    margin-left: var(--ion-margin-left-auto);
    margin-right: var(--ion-margin-right-auto);
    transform: scale(1.7);
  }

  ion-slides {
    .swiper-pagination-bullet {
      position: relative;
      background: var(--ion-color-common-very-dark-blue);
      opacity: var(--ion-opacity-05);
      top: 15px;
      width: var(--ion-width-15px);
      height: var(--ion-height-15px);
    }

    .swiper-pagination-bullet-active {
      opacity: var(--ion-opacity-1);
      background: dark;
    }
  }
}

// For iPad Pro
@media only screen and (width: 1024px) and (height: 1366px) {
  .cardComponent-wrapper {
    height: var(--ion-height-680px) !important;
    margin-top: var(--ion-margin-top-100px);
    ion-img {
      height: var(--ion-height-360px);
    }
  }
  .loading-Spinner {
    display: table;
    margin-left: var(--ion-margin-left-auto);
    margin-right: var(--ion-margin-right-auto);
    transform: scale(2);
  }

  ion-slides {
    .swiper-pagination-bullet {
      position: relative;
      background: var(--ion-color-common-very-dark-blue);
      opacity: var(--ion-opacity-05);
      top: 16px;
      width: var(--ion-width-15px);
      height: var(--ion-height-15px);
    }
    .swiper-pagination-bullet-active {
      opacity: var(--ion-opacity-1);
      background: dark;
    }
  }
}

ion-img {
  display: block;
  object-fit: cover;
  object-position: inherit;
  height: 184px;
  border-radius: 8px 8px 0px 0px;
}



//iphone 5/se
@media only screen and (width: 320px) and (height: 568px){
  .home-heading {
    padding-top: -1px;
  margin-top: -30px;}
    .heading-title {
      letter-spacing: var(--ion-letter-spacing);
    }
  .home-container .home-heading .heading-subtitle{
    margin-top: var(--ion-margin-top-12px);
      margin-bottom: 20px;
      font-size:16px;
  }
  .cardComponent-grid {
    --ion-grid-padding: 8px;
  }
  .cardComponent-wrapper .first-row .first-row-title {
    font-size: 17px;
    margin-top: -40px;
    
  }
  .cardComponent-wrapper .second-icon-row .icon-toggle-text {
    margin-top:20px;
    font-size: 15px;
  }
  .cardComponent-wrapper {
    height: 310px;
  }
  ion-img {
    height: 150px;
  }
  ion-slides {
    .swiper-pagination-bullet {
      position: relative;
      background: var(--ion-color-common-very-dark-blue);
      opacity: var(--ion-opacity-05);
      top: 16px;
      width: 7px;
      height: 7px;
    }
    .swiper-pagination-bullet-active { 
      opacity: var(--ion-opacity-1);
      background: dark;
    }
  }
}

//iphone 6/7/8
@media only screen and (width: 375px) and (height: 667px){
  .home-heading {
    padding-top: 0px;
  margin-top: -20px;}
  .heading-title {
    letter-spacing: var(--ion-letter-spacing);
    font-size: 28px;
  }
  .home-container .home-heading .heading-subtitle{
    margin-top: 10px;
    margin-bottom: 0px;
    font-size: 16px;
  }  
  .cardComponent-wrapper .first-row .first-row-title {
    font-size: 17px;
    margin-top: -5px;
    
  }  
  .cardComponent-wrapper .second-icon-row .icon-toggle-text {
    margin-top:60px;
    font-size: 15px;
  }
}


  
  

  

