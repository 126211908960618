.ripple-wrapper{
  background-color: white;
  color: var(--ion-color-common-orange);
  height: auto;
  width: auto;
  margin:auto;
}
.ripple-parent{
background-color: white;
color: var(--ion-color-common-orange);

}
  .ripple-effect1 {
    position: absolute;
  height: 3.2rem;
  width: 3.2rem;
  margin: 5% auto;
  border-radius: 12px;
}
.icon-image {
margin-top: auto;
margin-bottom: auto;
margin-right: auto;
margin-left: auto;
height: 3.2rem;
width: 3.2rem;
}
.icon-image-1 {
margin-top: auto;
margin-bottom: auto;
margin-right: auto;
margin-left: auto;
height: 3.2rem;
width: 3.2rem;
}

@media only screen and (min-width: 464px) and (max-width: 500px)  {
.ripple-effect1 {
position: absolute;
height: 3.2rem;
width: 3.2rem;
margin: 4% auto;
border-radius: 12px;
}
}
@media only screen and (min-width: 501px) and (max-width: 556px)  {
.ripple-effect1 {
position: absolute;
height: 3.2rem;
width: 3.2rem;
margin: 3% auto;
border-radius: 12px;
}
}
@media only screen and (min-width: 557px) and (max-width: 3000px)  {
.ripple-effect1 {
position: absolute;
height: 3.2rem;
width: 3.2rem;
margin: 3% auto;
border-radius: 12px;
}
}

@media only screen and (min-width: 768px) and (max-width: 3000px) and (min-height: 1024px) and (max-height: 2000px) {
.ripple-wrapper{
background-color: white;
color: var(--ion-color-common-orange);
height: auto;
width: auto;
margin:auto;
}
.ripple-parent{
background-color: white;
color: var(--ion-color-common-orange);

}
.ripple-effect1 {
  position: absolute;
height: 3.2rem;
width: 3.2rem;
margin: 3% auto!important;
border-radius: 12px;
}
.icon-image {
margin-top: auto;
margin-bottom: auto;
margin-right: auto;
margin-left: auto;
height: 3.2rem!important;
width: 3.2rem;
}
.icon-image-1 {
margin-top: auto;
margin-bottom: auto;
margin-right: auto;
margin-left: auto;
height: 3.2rem!important;
width: 3.2rem;
} 
}