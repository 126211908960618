.image-component-wrapper {
  height: 184px;
  .media-thumbnail-image {
    width: 100%;
  }

  .youtube-spinner {
    display: table;
    margin-left: auto;
    margin-right: auto;
    margin-top: -115px;
  }

  .show-youtube-icons {
    position: absolute;
    width: 40%;
    height: 26%;
    margin-left: 30%;
    top: 13%;
    background: rgba(196, 196, 196, 0.6);
    border-radius: 10px;

    .youtube-play-icons {
      position: relative;
      width: 45px;
      height: 32px;
      top: 35px;
      display: table;
      margin-left: auto;
      margin-right: auto;
    }
  }

  .ion-button {
    margin: 0px;
    width: 100%;
    height: var(--ion-height-170px);
    --background: var(--ion-color-common-white-blue-light);
    --border-radius: var(--ion-border-radius-0px);
    --box-shadow: none;

    .plus-icon {
      height: 50%;
      width: 100%;
      color: var(--ion-color-common-white-red-light);
    }
  }
}

// For iPad
@media only screen and (min-width: 768px) and (max-width: 3000px) and (min-height: 1024px) and (max-height: 2000px) {
  .image-component-wrapper {
    height: 260px;
    .youtube-spinner {
      display: table;
      margin-left: auto;
      margin-right: auto;
      margin-top: -15vh;
      transform: scale(1.5);
    }

    .show-youtube-icons {
      .youtube-play-icons {
        width: 65px;
        top: 40px;
      }
    }
  }
}

// For iPad pro
@media only screen and (width: 1024px) and (height: 1366px) and (orientation: portrait) {
  .image-component-wrapper {
    height: 360px;
    .youtube-spinner {
      display: table;
      margin-left: auto;
      margin-right: auto;
      margin-top: -14vh;
      transform: scale(1.8);
    }
    .show-youtube-icons {
      .youtube-play-icons {
        width: 75px;
        top: 60px;
      }
    }
  }

}
