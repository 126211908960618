.btn-element-wrapper {
  --background: #004777;
  //--box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  --border-radius: 10px;
  --line-height: 17px;
  --font-style: normal;
  --color: #ffffff;
  --font-weight: 500;
  height: 30px;
  --transition: uppercase;
}
