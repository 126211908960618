@import "./theme/fonts";
@import "./theme/variables";

.toolbar-background-md,
.toolbar-background-ios,
.toolbar-background-wp {
  background-color: color(white, "primary");
  --color: #ffffff;
  --background: #ffffff;
}

.tab-selected {
  color: var(--ion-color-common-orange);

  ion-label {
    font-family: var(--ion-font-family-noto-sans);
    font-style: var(--ion-font-style);
    font-weight: var(--ion-font-weight);
    font-size: var(--ion-font-size-type0);
    line-height: var(--ion-line-height-type0);
    color: var(--ion-color-common-black-red-light);
    display: flex;
    align-items: center;
    text-align: center;
  }
}

ion-tab-bar {
  justify-content: space-evenly;
  height: 9vh;
}

@media only screen and (min-width: 768px) and (max-width: 3000px) and (min-height: 1024px) and (max-height: 2000px) {
  .tab-wrapper {
    color: var(--ion-color-common-orange);
    ion-label {
      font-family: var(--ion-font-family-noto-sans);
      font-style: var(--ion-font-style);
      font-weight: var(--ion-font-weight);
      line-height: var(--ion-line-height-type0);
      color: var(--ion-color-common-black-red-light);
      font-size: 15px;
      display: flex;
      align-items: center;
      text-align: center;
    }
  }

  ion-tab-bar {
    ion-icon {
      font-size: var(--ion-font-size-type40) !important;
    }
  }
}

@media only screen and (width: 1024px) and (height: 1366px) and (orientation: portrait) {
  .tab-wrapper {
    color: var(--ion-color-common-orange);
    ion-label {
      font-family: var(--ion-font-family-noto-sans);
      font-style: var(--ion-font-style);
      font-weight: var(--ion-font-weight);
      font-size: var(--ion-font-size-type2);
      line-height: var(--ion-line-height-type0);
      color: var(--ion-color-common-black-red-light);
      display: flex;
      align-items: center;
      text-align: center;
    }
  }

  ion-tab-bar {
    ion-icon {
      font-size: var(--ion-font-size-type50) !important;
    }
  }
}
