/* Set variables for all modes */
:root {
  --bullet-background-active: red;

  --ion-color-primary: #ee7203;
  --ion-color-primary-shade: #3171e0;
  --ion-color-primary-tint: #4c8dff;

  --ion-color-secondary: #ee7203;
  --ion-color-medium: #92949c;
  --ion-color-dark: #222428;

  --ion-color-common-white: #ffffff;
  --ion-color-common-grey-light: #e8eaee;
  --ion-color-common-white-light: #eeeeee;
  --ion-color-common-white-black: #e5e5e5;
  --ion-color-common-white-blue-light: #f5f5f7;

  --ion-color-common-white-red-light: #aeaeae;

  --ion-color-common-black-red-light: #292929;
  --ion-color-common-grey: #808285;
  --ion-color-common-black: #0e0d0d;
  --ion-color-common-black-type1: #111111;

  --ion-color-common-skyblue: #04ace2;
  --ion-color-common-orange:#ee7203;


  --ion-color-common-very-dark-blue: #29292d;
  --ion-color-common-dark-grayish-blue: #808285;

  /*font-family */

  --ion-color-heading: palevioletred;

  /*common css property........................*/
  --ion-background-image: none;

  //border
  --ion-border-0: none;
  --ion-border-5px: 5px;

  //margin-left
  --ion-margin-left-auto: auto;
  --ion-margin-left-minus-10px: -10px;
  --ion-margin-left-70px: 70px;
  --ion-margin-left-85px: 85px;
  --ion-margin-left-20px: 20px;
  --ion-margin-left-17px: 17px;
  --ion-margin-left-18px: 18px;
  --ion-margin-left-15px: 15px;
  --ion-margin-left-30px: 30px;
  --ion-margin-left-40px: 40px;

  //margin right
  --ion-margin-right-auto: auto;
  --ion-margin-right-20px: 20px;

  //margin-top
  --ion-margin-top-10px: 10px;
  --ion-margin-top-12px: 12px;
  --ion-margin-top-16px: 16px;
  --ion-margin-top-minus-22px: -22px;
  --ion-margin-top-minus-24px: -24px;
  --ion-margin-top-20px: 20px;
  --ion-margin-top-30px: 30px;
  --ion-margin-top-40px: 40px;
  --ion-margin-top-50px: 50px;
  --ion-margin-top-65px: 65px;
  --ion-margin-top-80px: 80px;
  --ion-margin-top-90px: 90px;
  --ion-margin-top-100px: 100px;

  //margin-bottom
  --ion-margin-bottom-10px: 10px;
  --ion-margin-bottom-minus-15px: -15px;

  //border-radius

  --ion-border-radius-0px: 0px;
  --ion-border-radius-16px: 16px;
  --ion-border-radius-28px: 28px;

  //font-family

  --ion-font-family-noto-sans: Noto Sans;
  --ion-font-style: normal;
  --ion-font-weight: normal;

  //line-height

  --ion-line-height-type1: 20px;
  --ion-line-height-type2: 24px;
  --ion-line-height-type3: 36px;

  //font-size

  --ion-font-size-type0: 10px;
  --ion-font-size-type1: 14px;
  --ion-font-size-type2: 16px;
  --ion-font-size-type15: 15px;
  --ion-font-size-type17: 17px;
  --ion-font-size-type3: 28px;
  --ion-font-size-type40: 40px;
  --ion-font-size-type50: 50px;

  //letter-spacing

  --ion-letter-spacing: -0.3px;

  //width
  --ion-width-10px: 10px;
  --ion-width-15px: 15px;
  --ion-width-24px: 24px;
  --ion-width-55px: 55px;
  --ion-width-48px: 48px;
  --ion-width-100px: 100px;
  --ion-width-104px: 104px;
  --ion-min-width-290px: 290px;
  --ion-max-width-500px: 500px;
  --ion-max-width-650px: 650px;
  --ion-max-width-700px: 700px;

  //height
  --ion-height-10px: 10px;
  --ion-height-15px: 15px;
  --ion-height-24px: 24px;
  --ion-height-28px: 28px;
  --ion-height-60px: 60px;
  --ion-height-170px: 170px;
  --ion-height-184px: 184px;
  --ion-height-190px: 190px;
  --ion-height-260px: 260px;
  --ion-height-360px: 360px;
  --ion-height-390px: 390px;
  --ion-height-520px: 520px;
  --ion-height-680px: 680px;
  --ion-height-720px: 720px;

  //right
  --ion-right-10px: 10px;

  //opacity
  --ion-opacity-1: 1;
  --ion-opacity-02: 0.2;
  --ion-opacity-05: 0.5;

  /*header section............................*/

  --ion-color-header-white: #ffffff;
  --ion-color-header-shade: #e8eaee;
  --ion-color-header-black: #0e0d0d;
  --ion-color-header-black-type1: #111111;
  --ion-color-header-dark: #292929;

  --ion-height-header-type1: 15px;
  --ion-height-header-type2: 88px;

  --ion-padding-header-type1: 42px;

  --ion-font-size-header-type1: 10px;
  --ion-font-size-header-type2: 18px;
  --ion-font-size-header-type3: 24px;
}

/*this is another varible.css file content.........*/
/** Color Variables **/

$colorMap: (
  dark: (
    Dark: #292929,
  ),
  subdued: (
    Subdued: #808285,
  ),
  soft: (
    Soft: #f5f5f7,
  ),
  icons: (
    Icons: #aeaeae,
  ),
  border: (
    Border: #eeeeee,
  ),
  brand: (
    Brand: #04ace2,
  ),
  light: (
    Light: #ffffff,
  ),
  iconSoft: (
    Icon-Soft: #d1d1d1,
  ),
  backgroundBlur: (
    Background-Blur: #c4c4c4,
  ),
  shadow: (
    Shadow: #c4c4c4,
  ),
  primary: (
    base: #00d2a6,
    base-rgb: rgb(0, 210, 166),
    dark: rgba(0, 210, 166, 0.64),
  ),
  whiteColor: (
    base: #f5f5f5,
    a96: rgba(255, 255, 255, 0.96),
    a64: rgba(255, 255, 255, 0.64),
    a32: rgba(255, 255, 255, 0.32),
  ),
  darkGreyColor: (
    base: #9f9f9f,
  ),
  darkColor: (
    a96: rgba(0, 0, 0, 0.96),
    a64: rgba(0, 0, 0, 0.64),
    a36: rgba(0, 0, 0, 0.36),
  ),
  orangeColor: (
    base: #ffa500,
  ),
  elevation: (
    a00: #0e1010,
    a01: (
      linear-gradient(
        0deg,
        rgba(255, 255, 255, 0.07),
        rgba(255, 255, 255, 0.07)
      ),
      #0e1010,
    ),
    a02: (
      linear-gradient(
        0deg,
        rgba(255, 255, 255, 0.11),
        rgba(255, 255, 255, 0.11)
      ),
      #0e1010,
    ),
    a03: (
      linear-gradient(
        0deg,
        rgba(255, 255, 255, 0.15),
        rgba(255, 255, 255, 0.15)
      ),
      #0e1010,
    ),
    a04: (
      linear-gradient(
        0deg,
        rgba(255, 255, 255, 0.19),
        rgba(255, 255, 255, 0.19)
      ),
      #0e1010,
    ),
    a05: (
      linear-gradient(
        0deg,
        rgba(255, 255, 255, 0.24),
        rgba(255, 255, 255, 0.24)
      ),
      #0e1010,
    ),
  ),
  gradient: (
    light:
      linear-gradient(
        180deg,
        rgba(255, 255, 255, 0.06) 0%,
        rgba(255, 255, 255, 0) 100%
      ),
    dark:
      linear-gradient(179.65deg, #262828 0.28%, #1c1e1e 84.4%, #0e1010 99.67%),
  ),
  warning: (
    base: #ffb950,
  ),
  critical: (
    base: #f83a61,
  ),
  information: (
    base: #2e8ce4,
  ),
  glass: (
    thick: rgba(14, 16, 16, 0.8),
    medium: rgba(28, 30, 30, 0.64),
    thin: rgba(14, 16, 16, 0.24),
    primarythin: rgba(0, 210, 166, 0.08),
    criticalthin: rgba(248, 58, 97, 0.08),
    whitethin: rgba(255, 255, 255, 0.08),
  ),
  utility: (
    border: rgba(14, 16, 16, 0.16),
    outline: rgba(255, 255, 255, 0.08),
    separator: rgba(255, 255, 255, 0.12),
    disconnected: rgba(255, 0, 0, 0.5),
  ),
  background: (
    base: #0e0e0e,
  ),
);

@function getColor($color, $variant) {
  @return map-get(map-get($colorMap, $color), $variant);
}

@mixin color($color, $variant) {
  color: getColor($color, $variant);
}

/** Spacing Variables **/
$spaceMap: (
  xxxxs: 2px,
  xxxs: 4px,
  xxs: 8px,
  xs: 12px,
  s: 16px,
  m: 24px,
  l: 32px,
  xl: 48px,
  xxl: 64px,
  xxxl: 96px,
  xxxxl: 128px,
  xxxxxl: 192px,
  xxxxxxl: 258px,
);

@function getSpace($size) {
  @return map-get($spaceMap, $size);
}

/** Text Variables **/
$textSizeMap: (
  largeTitle: 32px,
  title1: 32px,
  title2: 22px,
  title3: 20px,
  heading1: 16px,
  heading2: 14px,
  body1: 16px,
  body2: 14px,
  button: 13px,
  caption1: 12px,
  caption2: 11px,
);

$textLineHeightMap: (
  largeTitle: 42px,
  title1: 36px,
  title2: 30px,
  title3: 28px,
  heading1: 24px,
  heading2: 20px,
  body1: 24px,
  body2: 20px,
  button: 18px,
  caption1: 16px,
  caption2: 14px,
);

$textLetterSpacingMap: (
  largeTitle: -0.2px,
  title1: 0.6px,
  title2: 0.3px,
  title3: 0px,
  heading1: 0px,
  heading2: 0px,
  body1: 0px,
  body2: 0px,
  button: 0.8px,
  caption1: 0px,
  caption2: 0.4px,
);

$textWeightMap: (
  largeTitle: 500,
  title1: 500,
  title2: 600,
  title3: 500,
  heading1: 600,
  heading2: 600,
  body1: 400,
  body2: 400,
  button: 600,
  caption1: 400,
  caption2: 500,
);

$textTextTransformMap: (
  largeTitle: none,
  title1: uppercase,
  title2: uppercase,
  title3: none,
  heading1: none,
  heading2: none,
  body1: none,
  body2: none,
  button1: uppercase,
  caption1: none,
  caption2: uppercase,
);

@mixin font($type: "body") {
  font-size: map-get($textSizeMap, $type);
  font-weight: map-get($textWeightMap, $type);
  line-height: map-get($textLineHeightMap, $type);
  letter-spacing: map-get($textLetterSpacingMap, $type);
  text-transform: map-get($textTextTransformMap, $type);
}

/*-------Text-styles--------*/
@mixin font($fontSize: 0px, $lineHeight: 0px) {
  font-family: Noto Sans;
  font-style: normal;
  font-weight: normal;
  font-size: $fontSize;
  line-height: $lineHeight;
  // color: #000000;
}
@mixin color($color, $variant) {
  color: getColor($color, $variant);
}
