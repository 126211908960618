@import "../../theme/variables.scss";

.cardComponent-grid {
  --ion-grid-padding: 20px;
}

.cardComponent-wrapper {
  width: 100%;
  height: var(--ion-height-390px);
  display: block;
  background: var(--ion-color-common-white);
  border: 1px solid var(--ion-color-common-white-light);
  box-sizing: border-box;
  border-radius: var(--ion-border-radius-16px);
  box-shadow: 0px 15px 15px var(--ion-color-common-white-black);
  margin: 0px auto;
  min-width: var(--ion-min-width-290px);
  max-width: var(--ion-max-width-500px);

  ion-card-content {
    display: contents;
    position: relative;
  }

  .switching-spinner {
    display: table;
    margin-left: var(--ion-margin-left-auto);
    margin-right: var(--ion-margin-right-auto);
    height: var(--ion-height-184px);
  }

  .card-second-row {
    margin-bottom: -15px;
    margin-top: 16px;
  }

  .ion-button {
    margin: 0px;
    width: 100%;
    height: var(--ion-height-170px);
    --background: var(--ion-color-common-white-blue-light);
    --border-radius: var(--ion-border-radius-0px);
    --box-shadow: none;

    .plus-icon {
      height: 50%;
      width: 100%;
      color: var(--ion-color-common-white-red-light);
    }
  }

  .card-component-title {
    margin-bottom: 6px;
    position: absolute;
    margin-left: 10px;
  }

  .card-component-label {
    margin-left: var(--ion-margin-left-17px);
    margin-bottom: 22px;
  }

  .forward-icon {
    width: 100%;
    height: var(--ion-height-24px);
    color: var(--ion-color-common-white-red-light);
    margin-top: 10px;
  }


  .youtube-toggle-wrapper {
    display: flex;
    margin-left: var(--ion-margin-left-18px);
    margin-top: 9px;
  }

  .card-youtube-row {
    border-top: 1px solid var(--ion-color-common-white-light);
    height: 46px;
  }

  .youtube-forward-icon {
    width: 100%;
    height: var(--ion-height-24px);
    color: var(--ion-color-common-white-red-light);
    margin-top: 10px;
  }

  .first-row {
    margin-top: var(--ion-margin-top-16px);
    margin-bottom: 8%;
    .first-row-title {
      font-size: var(--ion-font-size-type17);
      color: var(--ion-color-common-dark-grayish-blue);
      font-family: var(--ion-font-family-noto-sans);
      font-style: var(--ion-font-weight);
      font-weight: var(--ion-font-style);
      margin-left: var(--ion-margin-left-15px);
    }
  }

  .second-icon-row {
    .youtube-toggle-text {
      font-size: var(--ion-font-size-type15);
      line-height: var(--ion-line-height-type1);
      color: var(--ion-color-common-dark-grayish-blue);
      font-family: var(--ion-font-family-noto-sans);
      font-style: var(--ion-font-weight);
      font-weight: var(--ion-font-style);

    }
    .youtube-text {
      font-size: var(--ion-font-size-type15);
      line-height: var(--ion-line-height-type1);
      color: var(--ion-color-common-dark-grayish-blue);
      font-family: var(--ion-font-family-noto-sans);
      font-style: var(--ion-font-weight);
      font-weight: var(--ion-font-style);
    }
    
    .background-toggle-text {
      font-size: var(--ion-font-size-type15);
      line-height: var(--ion-line-height-type1);
      color: var(--ion-color-common-dark-grayish-blue);
      font-family: var(--ion-font-family-noto-sans);
      font-style: var(--ion-font-weight);
      font-weight: var(--ion-font-style);

    }
    .background-text{
      font-size: var(--ion-font-size-type15);
      line-height: var(--ion-line-height-type1);
      color: var(--ion-color-common-dark-grayish-blue);
      font-family: var(--ion-font-family-noto-sans);
      font-style: var(--ion-font-weight);
      font-weight: var(--ion-font-style);
    }
    .switch-toggle-text {
      font-size: var(--ion-font-size-type15);
      line-height: var(--ion-line-height-type1);
      color: var(--ion-color-common-dark-grayish-blue);
      font-family: var(--ion-font-family-noto-sans);
      font-style: var(--ion-font-weight);
      font-weight: var(--ion-font-style);

    }
    .switch-text{
      font-size: var(--ion-font-size-type15);
      line-height: var(--ion-line-height-type1);
      color: var(--ion-color-common-dark-grayish-blue);
      font-family: var(--ion-font-family-noto-sans);
      font-style: var(--ion-font-weight);
      font-weight: var(--ion-font-style);
    }
  }
}

@media only screen and (min-width: 100px) and (max-width: 3000px) {
  .cardComponent-wrapper{
    .second-icon-row {
      .youtube-toggle-text {
        width: 3.7rem;
        margin-left:auto;
        margin-right:auto;
        margin-top:auto;
        margin-bottom:auto;
      }
      .youtube-text{
        width: 3.7rem;
        margin-left:auto;
        margin-right:auto;
        margin-top:auto;
        margin-bottom:auto;
      }
      .background-toggle-text {
        width: 5.4rem;
        margin-left:auto;
        margin-right:auto;
        margin-top:auto;
        margin-bottom:auto;
      }
      .background-text{
        width: 5.4rem;
        margin-left:auto;
        margin-right:auto;
        margin-top:auto;
        margin-bottom:auto;
      }
      .switch-toggle-text {
        width: 3.1rem;
        margin-left:auto;
        margin-right:auto;
        margin-top:auto;
        margin-bottom:auto;
      }
      .switch-text{
        width: 3.1rem;
        margin-left:auto;
        margin-right:auto;
        margin-top:auto;
        margin-bottom:auto;
      }
    }
  }
}

@media only screen and (min-width: 768px) and (max-width: 3000px) and (min-height: 1024px) and (max-height: 2000px) {
  .cardComponent-wrapper{
    .second-icon-row {
      .youtube-toggle-text {
        width: 3.7rem;
        margin-left:auto;
        margin-right:auto;
        margin-top:auto;
        margin-bottom:auto;
      }
      .youtube-text{
        width: 3.7rem;
        margin-left:auto;
        margin-right:auto;
        margin-top:auto;
        margin-bottom:auto;
      }
      .background-toggle-text {
        width: 5.4rem;
        margin-left:auto;
        margin-right:auto;
        margin-top:auto;
        margin-bottom:auto;
      }
      .background-text{
        width: 5.4rem;
        margin-left:auto;
        margin-right:auto;
        margin-top:auto;
        margin-bottom:auto;
      }
      .switch-toggle-text {
        width: 3.1rem;
        margin-left:auto;
        margin-right:auto;
        margin-top:auto;
        margin-bottom:auto;
      }
      .switch-text{
        width: 3.1rem;
        margin-left:auto;
        margin-right:auto;
        margin-top:auto;
        margin-bottom:auto;
      }
    }
  }
}
