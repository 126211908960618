@import "../../theme/variables.scss";

.forgotPwd {
  text-align: center;
}

#forgot-password-page ion-row {
  align-items: center;
  justify-content: center;
  background-color: #ffffff;
}

.content {
  height: 100vh;
  display: flex;
  align-items: center;
  background-color: #ffffff;
}

#forgot-password-page .ionItem {
  padding: 0px !important;
  --padding-start: 0px !important;
  --inner-padding-end: 0px !important;
}

.forgotEmailRow {
  margin-top: getSpace("xxl");
}

.newPasswordRow {
  margin-bottom: getSpace("xl");
}

.forgotPasswordErrorRow {
  margin-top: getSpace("xxxxs");
}

.forgotPasswordErrorRow1 {
  margin-top: getSpace("xxxxs");
  margin-right: getSpace("m");
}

.forgotPasswordErrorRow2 {
  margin-top: getSpace("xxxxs");
  margin-left: getSpace("s");
}
.forgotPasswordErrorRow3 {
  margin-top: getSpace("xxxxs");
  margin-left: getSpace("xl");
}

.submitRow {
  margin-top: getSpace("xl");
}

.pwdButtonSubmit {
  width: var(--forgotPasswordPageElementsWidth, 256px) !important;
  height: 44px;
  text-transform: uppercase;
  color: #{getColor("darkColor", "a96")} !important;
  border-radius: getSpace("xxs");
}

.buttonContinue {
  width: var(--forgotPasswordPageElementsWidth, 256px) !important;
  height: 44px;
  font-family: var(--ion-font-family-noto-sans);
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 18px;
  text-align: center;
  letter-spacing: 0.8px;
  text-transform: uppercase;

  color: #{getColor("whiteColor", "base")} !important;
}

.backToLoginLink {
  text-decoration: none;
  cursor: pointer;
}

.emailRowItem {
  width: var(--forgotPasswordPageElementsWidth, 256px) !important;
  max-height: 32vh;
}

.codeRowItem {
  width: var(--forgotPasswordPageElementsWidth, 256px) !important;
  max-height: 32vh;
  margin-top: getSpace("xl");
}

.passwordRowItem {
  width: var(--forgotPasswordPageElementsWidth, 256px) !important;
  max-height: 32vh;
}

.message-title {
  white-space: pre-line;
  color: #{getColor("primary", "base")} !important;
  text-align: left;
  line-height: 20px;
}
.message-title-row {
  height: getSpace("xxl");
  background: rgba(0, 210, 166, 0.08) !important;
  border-radius: getSpace("xxs");
  margin-bottom: getSpace("l");
}

.checkmark {
  margin: 10px !important;
  text-align: center !important;
  color: #{getColor("primary", "base")} !important;
  margin-bottom: getSpace("s") !important;
  font-size: getSpace("m");
}

.title-row {
  margin-bottom: getSpace("m");
  margin-top: 10px;
}

.subtitle1 {
  white-space: pre-line;
  padding-left: 30px;
  padding-right: 30px;
}
.title1 {
  line-height: 16px;
}
#forgot-password-page .arrowBack {
  color: #{getColor("whiteColor", "a96")} !important;
  margin-top: getSpace("l");
  margin-bottom: getSpace("s");
  height: getSpace("m");
}
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: 0;
}

.forgotPwd2 {
  margin-top: getSpace("m");
}

.ionInput {
  border-bottom: 1px solid getColor("utility", "separator") !important;
}

.ionInput input {
  $InputHeight1: getSpace("xl");
  $InputHeight2: getSpace("xxs");
  height: $InputHeight1 + $InputHeight2 !important;
}

// @media (min-width: 992px) {
//   #forgot-password-page {
//     --forgotPasswordPageElementsWidth: 320px;
//   }
// }

// @media (min-width: 401px) and (max-width: 599px) and (orientation: portrait) {
//   .title-row {
//     @include font("title2");
//   }

//   .subtitle1 {
//     white-space: pre-line;
//     width: 265px;
//   }

//   .forgotPwd2 {
//     width: 410px;
//     text-align: center;
//   }

//   .message-title-row {
//     width: 360px;
//     margin-right: 10px;
//     height: auto;
//   }
// }

// @media (min-width: 361px) and (max-width: 400px) and (orientation: portrait) {
//   .title-row {
//     @include font("title2");
//     font-size: 20px;
//   }

//   .subtitle1 {
//     white-space: pre-line;
//     width: 351px;
//     @include font("title2");
//     line-height: 16px;
//   }

//   .forgotPwd2 {
//     width: 360px;
//     text-align: center;
//   }

//   .message-title-row {
//     width: 330px;
//     height: auto;
//   }
// }

// @media (min-width: 320px) and (max-width: 360px) and (orientation: portrait) {
//   .title-row {
//     @include font("title2");
//   }

//   .subtitle1 {
//     white-space: pre-line;
//     width: 265px;
//   }

//   .forgotPwd2 {
//     width: 360px;
//     text-align: center;
//   }

//   .message-title-row {
//     width: 330px;
//     height: auto;
//     margin-right: 12px;
//   }
// }
