@import "../../theme/variables.scss";

#login-page ion-row {
  align-items: center;
  justify-content: center;
}

.content {
  height: 100vh;
  display: flex;
  align-items: center;
  background-color: #ffffff;
}

.greetMsg {
  text-align: center;
}

.passwordRow {
  margin-top: getSpace("xl");
}

.errorRowNewPwd {
  margin-top: getSpace("s");
}

.subText {
  text-align: center;
}

.submitRow {
  margin-top: 56px;
}

.error-for-newpassword {
  white-space: pre-line !important;
  // margin-left: 15% !important;
  margin-top: -115px !important;

  .error-for-newpassword-typo {
    color: yellow;
  }
}
