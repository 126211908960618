@import "../../theme/variables.scss";

.media-container {
  --padding-top: 5px;
  font-family: Noto Sans;

  .media-heading {
    position: absolute;
    height: 48px;
  }
  .img-wrapper {
    margin-top: 40px;
    margin-bottom: 80px;

    .image-bild-button {
      position: relative;
      ion-img {
        position: relative;
        padding-bottom: 3px;
        display: block;
        width: 100%;
        left: 0px;
        top: 0px;
        z-index: -1;
        height: 170px;
      }

      .bottom-left {
        position: absolute;
        bottom: 8px;
        right: 8px;
        color: #ffffff;
        font-size: 12px;
        line-height: 18px;
        background-color: black;
        border-radius: 32px;
        line-height: 18px;
        background: rgba(41, 41, 41, 0.88);
        padding: 1px 8px 1px 8px;
        font-family: Noto Sans;
        font-style: normal;
        font-weight: normal;
      }
      .switching-spinner {
        align-self: center;
        height: 50px;
        position: absolute;
        background-color: transparent;
        top: 75px;
        right: 185px;
      }

      .download-center {
        position: absolute;
        top: 30%;
        bottom: 50%;
        right: 50%;
        left: 50%;
        font-size: 12px;
        line-height: 18px;
        background-color: transparent;
        border-radius: 32px;
        background: transparent;
        padding: 1px 8px 1px 8px;
        display: flex;
        justify-content: center;
        
          .download-icon {
            @include color("subdued", "Subdued");
            position: absolute;
            top: 50%;
            left: -0.005vh;
            height: 50%;
            transform: translate(-50%, -50%);
            vertical-align: middle;
            width: 30px;
            height: 30px;
          }
          .download-text {
            justify-content: center;
            position: absolute;
            right : -37px;
            bottom: -45px;
            height: 48px;
            width: 100px;
            font-size: 12px;
            font-family: Noto Sans;
            font-style: normal;
            font-weight: normal;
          }
      }

      .preview-and-purchase-app{
      position: absolute;
      width: 100%;
      height: 170px;
      left: 0px;
      top: 0px;
      background: rgba(196, 196, 196, 0.8);

       .icons-row-preview{
        display: flex;
        flex-wrap: nowrap;
        margin: 40px -0% 10px 20%;

        .preview-svg-img{
          height: 40px;
          width: 45px;
          color: #434343;
          
        }
        .preview-svg-img1{
          height: 40px;
          width: 45px;
          color: #434343;
        }

        .purchase-typo-text{
          margin-left: -6px;
          margin-top: 5px;
          }

          .purchase-typo-text1{
            margin-left: -6px;
            margin-top: 0px;
            background: #434343;
          }
        }
      }
      .youtube-list {
        position: absolute;
        width: 100%;
        height: 98%;
        left: 0px;
        top: 0px;
        // background: rgba(196, 196, 196, 0.8);
        background-image: linear-gradient(
          to bottom,
          rgba(255, 0, 0, 0),
          rgb(100, 98, 98)
        );

        .youtube-title-text {
          color: white;
          margin-top: 125px;
          margin-left: 0px;
          white-space: nowrap;
          width: 100%; /* IE6 needs any width */
          overflow: hidden; /* "overflow" value must be different from  visible"*/
          -o-text-overflow: ellipsis; /* Opera < 11*/
          text-overflow: ellipsis;
        }
      }
      .play-icon {
        margin-left: -14px;
        margin-top: 92px;
        height: 40px;
        width: 45px;
        color: white;
      }
    }
  }

  .download-spinner {
    @include color("subdued", "Subdued");
    position: absolute;
    top: 42%;
    left: 50%;
    height: 50%;
    transform: translate(-50%, -50%);
    vertical-align: middle;
    width: 30px;
  }

  img {
    display: block;
    width: 100%;
    height: 151px;
  }
}


//iphone 5/se 
@media only screen and (width: 320px) and (height:568px){
.image-bild-button
  {
position:absolute;
padding: 14px 0px 0px 0px;
margin: -3px 0px -15px 0px;
}
.media-heading{
  margin-top: -14px;
}
}