@import "../theme/variables.scss";

.setting-container {
  font-family: var(--ion-font-family-noto-sans);
  font-weight: var(--ion-font-weight);
  font-style: var(--ion-font-style);
  .pelzer-settings-image-section {
    margin-bottom: 30px;
    .item-divider-wrapper-1 {
      background-color: white;
    }
    .item-divider-wrapper-2 {
      background-color: white;
      margin-top: 5px;
    }

    ion-item {
      --padding-start: 20px;
    }
  }

  .pelzer-heading-wrapper {
    padding-left: 16px;
    .pelzer-settings-title {
      letter-spacing: var(--ion-letter-spacing);
      font-size: 16px;
      line-height: 24px;
    }

    .pelzer-settings-subtitle {
      margin-top: var(--ion-margin-top-12px);
      left: 4%;
      right: 4.53%;
      bottom: 32.76%;
      font-size: 16px;
      line-height: 24px;
      color: #808285;
    }
    .pelzer-settings-number {
      color: #ee7203;
      margin-top: var(--ion-margin-top-12px);
      left: 4%;
      right: 4.53%;
      top: 51.85%;
      font-size: 16px;
      font-weight: 400px;
      line-height: 24px;
    }
  }

  .pelzer-settings-below-button {
    position: absolute;
    width: 90%;
    height: 52px;
    left: 10px;
    top: 350px;
    border: 1px solid #eeeeee;
    box-sizing: border-box;
    border-radius: 8px;
    background-color: #ffffff;

    .pelzer-settings-below-button-inside {
      position: absolute;
      left: 31.2%;
      right: 28.93%;
      top: 25.91%;
      bottom: 23.14%;
      font-weight: bold;
      background-color: #ffffff;
    }
  }

  .pelzer-settings-version {
    margin-top: 150px;
    margin-left: 0px;
    font-size: 14px;
    line-height: 20px;
  }

  .moreContainer {
    padding: 0px 15px;
  }

  .moreCard {
    border: 1px solid #eeeeee;
    box-sizing: border-box;
    background-color: #ffffff;

    margin: 0px;
    margin-top: getSpace("l");
    margin-bottom: getSpace("l");
    padding: getSpace("xs");
    border-radius: getSpace("xxs");
    display: flex;
    align-items: center;
    .icon {
      margin-right: getSpace("m");
      padding: getSpace("xxxs");
      background: #{getColor("soft", "Soft")};
      border-radius: 20px;
      .moreCardIonThumbnailIcon {
        @include color("dark", "Dark");
      }
    }
  }
}


/*--------ipad-------- */
@media only screen and (min-width: 768px) and (max-height: 1024px) {
  .setting-container {
  ion-col{
    margin-left: none; 
    flex:none; 
    width: 100%; 
    max-width: none;
    
    
    }
  }

}

//iphone 5/se
@media only screen and (min-width: 320px) and (max-height: 568px){
  .setting-container .moreCard{
    border: 1px solid #eeeeee;
    box-sizing: border-box;
    background-color: #ffffff;
    margin: 0px;
    margin-top: 12px;
    margin-bottom: getSpace("l");
    padding: 4px;
    border-radius: getSpace("xxs");
    display: flex;
    align-items: center;
    
  }
  .setting-container .pelzer-heading-wrapper .pelzer-settings-title {
    letter-spacing: var(--ion-letter-spacing);
    font-size: 16px;
    line-height: 24px;
}
.setting-container .pelzer-heading-wrapper .pelzer-settings-subtitle {
  margin-top: var(--ion-margin-top-12px);
  left: 4%;
  right: 4.53%;
  bottom: 32.76%;
  font-size: 16px;
  line-height: 24px;
  color: #808285;
}
.setting-container .pelzer-heading-wrapper .pelzer-settings-number {
  margin-top: var(--ion-margin-top-12px);
  left: 4%;
  right: 4.53%;
  top: 51.85%;
  font-size: 16px;
  font-weight: 400px;
  line-height: 24px;
}
.setting-container .pelzer-settings-below-button{
  position: absolute;
    width: 90%;
    height: 40px;
    left: 16px;
    top: 350px;
    border: 1px solid #eeeeee;
    box-sizing: border-box;
    border-radius: 8px;
    background-color: #ffffff;
}
.setting-container .pelzer-settings-below-button .pelzer-settings-below-button-inside {
  position: absolute;
  left: 28.2%;
  right: 28.93%;
  top: 15.91%;
  bottom: 23.14%;
  font-weight: bold;
  background-color: #ffffff;
}
.setting-container .pelzer-settings-version {
  margin-top: 100px;
  margin-left: 0px;
  font-size: 14px;
  line-height: 20px;
}
}