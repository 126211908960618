@font-face {
  font-family: var(--ion-font-family-noto-sans);
  src: url("../assets/fonts/Inter-Regular.woff") format("woff");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: var(--ion-font-family-noto-sans);
  src: url("../assets/fonts/Inter-Medium.woff") format("woff");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: var(--ion-font-family-noto-sans);
  src: url("../assets/fonts/Inter-SemiBold.woff") format("woff");
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: var(--ion-font-family-noto-sans);
  src: url("../assets/fonts/Inter-Bold.woff") format("woff");
  font-weight: 700;
  font-style: normal;
}
