.toolbar {
  --background-image: var(--ion-background-image);
  background: var(--ion-color-header-white);
  --min-height: 60px !important;
  text-align: center !important;
  box-shadow: 0px 0.5px 0px var(--ion-color-header-shade);
}

.pelzerlogo {
  max-width: 120px;
  text-align: center !important;
  margin-bottom: -10px;
}

.header-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  align-content: stretch;
  margin-bottom: -20px;
}

.back-button {
  align-self: flex-start;
  width: 40px !important;
  font-size: var(--ion-font-size-header-type1);
  --color: var(--ion-color-header-black);
  --background: var(--ion-color-header-white);
}

.back-arrow {
  color: var(--ion-color-header-dark);
  font-size: var(--ion-font-size-header-type3);
}

.heading {
  align-self: center;
  --color: var(--ion-color-header-dark);
  font-size: 16px;
  font-family: var(--ion-font-family-noto-sans);
  font-style: normal;
  font-weight: normal;
}

.empty-container {
  width: 40px !important;
}

